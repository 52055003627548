export const menuKeys = ['home', 'product', 'studio', 'news', 'jobs'];

export const menuList = [
  {
    title: 'Home',
    link: menuKeys[0],
  },
  {
    title: 'Product',
    link: menuKeys[1],
  },
  {
    title: 'Corporate',
    link: menuKeys[2],
  },
  {
    title: 'News',
    link: menuKeys[3],
  },
  {
    title: 'Jobs',
    link: menuKeys[4],
  },
];
