import React from 'react';
import { Link } from 'gatsby';
import { getLangFile } from 'utils/lang';
import AnimatedLink from 'components/AnimatedLink/AnimatedLink';
import Container from 'components/Container/Container';
import logo from 'assets/images/logo.svg';
import './Header.scss';

const Header = () => (
  <div className="Header">
    <Container>
      <div className="Header-inner">
        <Link height="32px" className="Header-logo" to="/">
          <img src={logo} />
        </Link>
        <AnimatedLink text={getLangFile().contactUs.getInTouch} link="/contact" />
      </div>
    </Container>
  </div>
);

export default Header;
