import React from 'react';
import window from 'global';
import { ribbonGradient, ribbonBlackGradient } from 'constants/gradients';
import './Ribbon.scss';

const getPadding = width => {
  // if (width < 600) return 0;
  if (width < 600) return 80;
  if (width > 1400) return 200;
  return 80;
};

const getRGBString = ([r, g, b]) => {
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

class Segment {
  // TOP to BOTTOM
  start = null;
  end = null;
  startBottom = null;
  endBottom = null;
  next = null;
  reverse = false;
  menuIndex = 0;
  lightColorBeforeChange = [
    //start and end color
    [80, 0, 249],
    // middle color
    [115, 50, 255],
  ];
  darkColorBeforeChange = [[48, 0, 150], [81, 0, 255]];
  animatedLightColor = [[80, 0, 249], [115, 50, 255]];
  animatedDarkColor = [[48, 0, 150], [81, 0, 255]];

  count = 0;

  constructor(start, end, reverse) {
    this.start = start;
    this.end = end;
    this.reverse = reverse || false;
  }

  joint(segment) {
    segment.start = this.end;
    this.next = segment;
  }

  //current color === animatedColor
  changeColors(animatedColor, newColor, colorBeforeChange) {
    let change = Math.abs((newColor - colorBeforeChange) / 20);

    if (newColor > animatedColor) {
      return Math.round(animatedColor + change);
    }
    return Math.round(animatedColor - change);
  }

  render(ctx, newMenuIndex, canvasWidth) {
    //update width

    if (canvasWidth !== this.segmentWidth) {
      if (this.start.x > 300) {
        this.start.x = canvasWidth - getPadding(canvasWidth);
        // this.start.x = canvasWidth;
      }
      if (this.end.x > 300) {
        this.end.x = canvasWidth - getPadding(canvasWidth);
        // this.end.x = canvasWidth;
      }

      if (this.start.x < 240) {
        this.start.x = getPadding(canvasWidth);
      }

      if (this.end.x < 240) {
        this.end.x = getPadding(canvasWidth);
      }
      this.segmentWidth = canvasWidth;
    }

    const paddingFromRight = canvasWidth - getPadding(canvasWidth);
    //1. the black ribbon
    ctx.beginPath();
    ctx.moveTo(this.start.x > 240 ? getPadding(canvasWidth) : paddingFromRight, this.start.y);
    ctx.lineTo(this.start.x > 240 ? getPadding(canvasWidth) : paddingFromRight, this.start.y + 320);
    ctx.lineTo(this.end.x > 240 ? getPadding(canvasWidth) : paddingFromRight, this.end.y + 320);
    ctx.lineTo(this.end.x > 240 ? getPadding(canvasWidth) : paddingFromRight, this.end.y);
    // ctx.moveTo(this.start.x > 240 ? getPadding(canvasWidth) : canvasWidth, this.start.y);
    // ctx.lineTo(this.start.x > 240 ? getPadding(canvasWidth) : canvasWidth, this.start.y + 320);
    // ctx.lineTo(this.end.x > 240 ? getPadding(canvasWidth) : canvasWidth, this.end.y + 320);
    // ctx.lineTo(this.end.x > 240 ? getPadding(canvasWidth) : canvasWidth, this.end.y);
    ctx.closePath();

    let grd = ctx.createLinearGradient(this.start.x, this.start.y, this.end.x, this.end.y);
    if (this.start.x > 240) {
      grd.addColorStop(0, ribbonBlackGradient.light[0]);
      grd.addColorStop(0.5, ribbonBlackGradient.light[50]);
      grd.addColorStop(1, ribbonBlackGradient.light[100]);
    } else {
      grd.addColorStop(0, ribbonBlackGradient.dark[0]);
      grd.addColorStop(0.5, ribbonBlackGradient.dark[50]);
      grd.addColorStop(1, ribbonBlackGradient.dark[100]);
    }
    ctx.fillStyle = grd;
    ctx.fill();

    //2. the colorful ribbon
    ctx.beginPath();
    ctx.moveTo(this.start.x, this.start.y);
    ctx.lineTo(this.start.x, this.start.y + 320);
    ctx.lineTo(this.end.x, this.end.y + 320);
    ctx.lineTo(this.end.x, this.end.y);
    ctx.closePath();
    ctx.fillStyle = this.fillStyle;

    if (this.menuIndex !== newMenuIndex) {
      this.count += 1;

      this.newDarkColors = ribbonGradient[newMenuIndex].dark;
      this.newLightColors = ribbonGradient[newMenuIndex].light;

      this.newDarkColors.map((value, gradientIndex) => {
        return value.map((newC, index) => {
          var changedColor = this.changeColors(
            this.animatedDarkColor[gradientIndex][index],
            newC,
            this.darkColorBeforeChange[gradientIndex][index]
          );
          this.animatedDarkColor[gradientIndex][index] = this.count >= 29 ? newC : changedColor;

          var lightChangedColor = this.changeColors(
            this.animatedLightColor[gradientIndex][index],
            this.newLightColors[gradientIndex][index],
            this.lightColorBeforeChange[gradientIndex][index]
          );
          this.animatedLightColor[gradientIndex][index] =
            this.count >= 29 ? this.newLightColors[gradientIndex][index] : lightChangedColor;
        });
      });

      if (this.count === 30) {
        this.menuIndex = newMenuIndex;
        this.lightColorBeforeChange = this.animatedLightColor;
        this.darkColorBeforeChange = this.animatedDarkColor;
        this.count = 0;
      }
    }

    if (this.start.x > this.end.x) {
      let grd = ctx.createLinearGradient(this.start.x, this.start.y, this.end.x, this.end.y);
      grd.addColorStop(0, getRGBString(this.animatedDarkColor[0]));
      grd.addColorStop(0.5, getRGBString(this.animatedDarkColor[1]));
      grd.addColorStop(1, getRGBString(this.animatedDarkColor[0]));
      ctx.fillStyle = grd;

      ctx.globalCompositeOperation = 'destination-over';
    } else {
      let grd = ctx.createLinearGradient(this.start.x, this.start.y, this.end.x, this.end.y);
      grd.addColorStop(0, getRGBString(this.animatedLightColor[0]));
      grd.addColorStop(0.5, getRGBString(this.animatedLightColor[1]));
      grd.addColorStop(1, getRGBString(this.animatedLightColor[0]));
      ctx.fillStyle = grd;

      ctx.globalCompositeOperation = 'source-over';
    }

    ctx.fill();
  }
}

class Ribbon {
  stage = null;
  segmentWidth = null;
  firstSegment = null;

  direction = -1; // -1: UP, +1: DOWN

  constructor(stage, menuIndex) {
    this.segmentWidth = stage.width;
    this.stage = stage;
    this.menuIndex = menuIndex;
    this.segmentWidth = this.stage.canvas.width;

    // とりあえず定位置でやってみる
    const paddingFromRight = getPadding(this.stage.canvas.width);
    this.firstSegment = new Segment(
      new Point(this.stage.canvas.width - paddingFromRight, -(stage.height / 1.4)),
      // new Point(this.stage.canvas.width, -(stage.height / 1.4)),
      new Point(paddingFromRight, 80)
    );
    this.fillSegment(this.firstSegment);
  }

  fillSegment(prev) {
    const { height } = this.stage;

    if (prev.end.y < height) {
      prev = this.addSegment(prev);
    }
  }

  addSegment(prev) {
    const { width, height } = this.stage;
    let end = new Point();
    end.y = prev.end.y + height / 1.4;
    if (prev.end.x < width / 1.4) {
      end.x = width - getPadding(width);
      // end.x = width;
    } else {
      end.x = getPadding(width);
    }
    const s = new Segment(null, end, !prev.reverse);
    prev.joint(s);
    return s;
  }

  render() {
    const { ctx, height, width } = this.stage;

    // the first
    let segment = this.firstSegment;
    while (segment) {
      segment.start.y += Math.random() * 0.8 * this.direction;
      if (!segment.next) {
        segment.end.y += Math.random() * 0.8 * this.direction;
        if (segment.end.y < height) {
          this.fillSegment(segment);
        }
      }
      segment = segment.next;
    }

    // render segments
    segment = this.firstSegment;
    while (segment) {
      segment.render(ctx, this.menuIndex, width);
      segment = segment.next;
    }
  }
}

class Stage {
  canvas = null;
  ctx = null;
  elements = [];

  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
  }

  get width() {
    return this.canvas.width;
  }

  get height() {
    return this.canvas.height;
  }

  add(element) {
    this.elements.push(element);
  }

  clear() {
    const { width, height } = this.canvas;
    this.ctx.clearRect(0, 0, width, height);
  }

  render() {
    this.clear();
    this.elements.forEach(element => element.render());
  }
}

class RibbonRender extends React.Component {
  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    this.ribbon.width = this.wrapper.clientWidth;
    this.ribbon.height = this.wrapper.clientHeight;

    this.stage = new Stage(this.ribbon);
    this.myNewRibo = new Ribbon(this.stage, this.props.menuIndex);
    this.stage.add(this.myNewRibo);
    window.requestAnimationFrame(this.animate);
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  animate = () => {
    this.myNewRibo.menuIndex = this.props.menuIndex;
    this.stage.render(this.ribbon);
    window.requestAnimationFrame(this.animate);
  };

  getDimensions = e => {
    this.ribbon.width = e.target.innerWidth;
    this.ribbon.height = this.wrapper.clientHeight;
  };

  updateDimensions = e => {
    var delay = 250;
    this.throttled = false;

    if (!this.throttled) {
      this.throttled = true;
      window.setTimeout(() => {
        this.getDimensions(e);
        this.throttled = false;
      }, delay);
    }
  };

  render() {
    return (
      <div className="RibbonRender" ref={wrapper => (this.wrapper = wrapper)}>
        <canvas id="canvas" ref={ribbon => (this.ribbon = ribbon)} />
      </div>
    );
  }
}

export default RibbonRender;
