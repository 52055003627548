import React from 'react';
import cx from 'classnames';
import window from 'global';
import './BirdsAnimation.scss';
class BirdsAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.yourElement = React.createRef();
  }

  componentDidMount() {
    const yourElement = this.yourElement.current;

    if (typeof window !== undefined) {
      console.log('this.effect', window.BIRDS);

      this.effect = window.VANTA.BIRDS({
        el: yourElement,
        color1: 0xff,
        color2: 0xffffff,
        colorMode: 'variance',
        quantity: 4.0,
        backgroundAlpha: 0.0,
      });
    }
  }

  componentWillUnmount() {
    if (this.effect) this.effect.destroy();
  }
  render() {
    return (
      <div
        className={cx('BirdsAnimation', {
          'BirdsAnimation-on': this.props.toggleBirds,
        })}
        ref={this.yourElement}
      />
    );
  }
}

export default BirdsAnimation;
