import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isEnglish, changeLang } from 'utils/lang';
import './SideInfo.scss';

class SideInfo extends React.Component {
  changeLang = en => {
    changeLang(en);
    this.props.update();
  };

  render() {
    const { isActive } = this.props;

    return (
      <>
        <p className="SideInfo-address">6-28-5 JINGUMAE, SHIBUYA, TOKYO</p>
        <p className="SideInfo-language">
          <a
            onClick={() => this.changeLang('en')}
            className={cx('Side-language-link', {
              'Side-language-link--active': isEnglish(isActive),
            })}>
            EN
          </a>
          |
          <a
            onClick={() => this.changeLang('ja')}
            className={cx('Side-language-link', {
              'Side-language-link--active': isActive === 'ja',
            })}>
            JA
          </a>
        </p>
      </>
    );
  }
}

SideInfo.propTypes = {
  isActive: PropTypes.string,
};

SideInfo.defaultProps = {
  isActive: 'ja',
};

export default SideInfo;
