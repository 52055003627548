export const ribbonBlackGradient = {
  light: {
    0: '#1D1F24',
    50: '#2B2E35',
    100: '#1D1F24',
  },
  dark: {
    0: '#151619',
    50: '#1D1F24',
    100: '#151619',
  },
};

// export const ribbonGradient = [
//   //menu0 colors
//   {
//     light: {
//       0: [80, 0, 249],
//       50: [115, 50, 255],
//     },
//     dark: {
//       0: [48, 0, 150],
//       50: [81, 0, 255],
//     },
//   },
//   //menu1 colors
//   {
//     light: {
//       0: [124, 0, 249],
//       50: [162, 68, 255],
//     },
//     dark: {
//       0: [111, 12, 209],
//       50: [92, 13, 171],
//     },
//   },
//   //menu2 colors
//   {
//     light: {
//       0: [214, 0, 249],
//       50: [237, 123, 255],
//     },
//     dark: {
//       0: [196, 0, 227],
//       50: [137, 16, 156],
//     },
//   },
//   //menu3 colors
//   {
//     light: {
//       0: [249, 0, 119],
//       50: [255, 64, 156],
//     },
//     dark: {
//       0: [223, 0, 107],
//       50: [182, 0, 88],
//     },
//   },
//   //menu4 colors //same as menu0
//   {
//     light: {
//       0: [80, 0, 249],
//       50: [115, 50, 255],
//     },
//     dark: {
//       0: [48, 0, 150],
//       50: [81, 0, 255],
//     },
//   },
// ];

export const ribbonGradient = [
  //menu0 colors
  {
    light: [[80, 0, 249], [115, 50, 255]],
    dark: [[48, 0, 150], [81, 0, 255]],
  },
  //menu1 colors
  {
    light: [[124, 0, 249], [162, 68, 255]],
    dark: [[111, 12, 209], [92, 13, 171]],
  },
  //menu2 colors
  {
    light: [[214, 0, 249], [237, 123, 255]],
    dark: [[196, 0, 227], [137, 16, 156]],
  },
  //menu3 colors
  {
    light: [[249, 0, 119], [255, 64, 156]],
    dark: [[223, 0, 107], [182, 0, 88]],
  },
  //menu4 colors //same as menu0
  {
    light: [[80, 0, 249], [115, 50, 255]],
    dark: [[48, 0, 150], [81, 0, 255]],
  },
];

export const singleColorRibbon = [
  { light: [80, 0, 249, 1], dark: [48, 0, 150, 1] },
  { light: [124, 0, 249, 1], dark: [107, 0, 214, 1] },
  { light: [218, 23, 250, 1], dark: [196, 0, 227, 1] },
  { light: [249, 0, 119, 1], dark: [223, 0, 107, 1] },
  { light: [80, 0, 249, 1], dark: [48, 0, 150, 1] },
];
