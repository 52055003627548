import React from 'react';
import { getLangFile } from 'utils/lang';
import AnimatedLink from 'components/AnimatedLink/AnimatedLink';
import './ContactUs.scss';

const ContactUs = () => {
  const t = getLangFile().contactUs;

  return (
    <div className="ContactUs">
      <h1>{t.title}</h1>
      <div className="ContactUs-line">
        <div>{t.text}</div>
      </div>
      <div className="ContactUs-form">問い合わせ先：
        <a href="mailto:info@aivia.co.jp?subject=お問い合わせ&body=こちらにお問い合わせ内容を入力してください。">info@aivia.co.jp</a>
      </div>
    </div>
  );
};

export default ContactUs;
