import React from 'react';
import Helmet from 'react-helmet';
import favicon16 from 'assets/images/favicon/favicon-16x16.png';
import favicon32 from 'assets/images/favicon/favicon-32x32.png';
import ogImage from 'assets/images/og.jpg';

const MetaTags = () => (
  <Helmet>
    <title>
      Alpha &#8211;
      Alphaは3D技術を武器に、3DCG広告の「3D AD」を運営する会社です。
    </title>
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
    />

    <link rel="canonical" href="https://alpha.inc/" />
    <link rel="icon" href={favicon16} type="image/gif" sizes="16x16" />
    <link rel="icon" href={favicon32} type="image/gif" sizes="32x32" />

    <meta name="msapplication-TileColor" content="rgb(95, 22, 252)" />
    <meta name="web_author" content="株式会社Alpha" />
    <meta name="theme-color" content="rgb(95, 22, 252)" />
    <meta name="image" content={ogImage} />

    {/* OpenGraph tags */}
    <meta property="og:type" content="Website" />
    <meta property="og:url" content="https://alpha.inc/" />
    <meta property="og:title" content="Alpha" />
    <meta property="og:image" content={ogImage} />
    <meta
      property="og:description"
      content="Alphaは3D技術を武器に、3DCG広告の「3D AD」を運営する会社です。"
    />

    {/* stylesheet */}
    <link rel="stylesheet" href="assets/fonts" />
    <link rel="stylesheet" href="styles/global.scss" />
  </Helmet>
);

export default MetaTags;
