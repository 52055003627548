import React from 'react';
import PropTypes from 'prop-types';
import { TweenLite } from 'gsap';
import cx from 'classnames';
import posed, { PoseGroup } from 'react-pose';
import { getLang } from 'utils/lang';
import Scroll from 'react-scroll';
import window from 'global';
import Media from 'react-media';

import BirdsAnimation from 'components/BirdsAnimation/BirdsAnimation';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Spinner from 'components/Spinner/Spinner';
import SideInfo from 'components/SideInfo/SideInfo';
import RibbonRender from 'components/Ribbon/Ribbon';
import Menu from 'components/Menu/Menu';
import MenuSm from 'components/Menu/MenuSm';
import { menuKeys } from 'constants/menu';
import MetaTags from './MetaTag';

import './Layout.scss';

const transitionDuration = 300;
const transitionDelay = 350;

const Transition = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: transitionDuration },
    delay: transitionDelay,
    beforeChildren: true,
  },
  exit: {
    opacity: 0,
    delay: transitionDelay,
    transition: { duration: transitionDuration },
  },
});

class Layout extends React.Component {
  state = {
    hovered: false,
    menuClicked: false,
    currentIndex: 0,
    loading: false,
  };

  componentDidMount() {
    Scroll.Events.scrollEvent.register('begin', (to, element) => {
      this.setState({
        currentIndex: menuKeys.indexOf(to),
        menuClicked: true,
      });
    });

    Scroll.Events.scrollEvent.register('end', (to, element) => {
      this.setState({
        menuClicked: false,
      });
    });
  }

  componentWillUnmount() {
    Scroll.Events.scrollEvent.remove('begin');
    Scroll.Events.scrollEvent.remove('end');
  }

  updateMenuIndex = menuIndex => {
    if (this.state.menuClicked) return;
    this.setState({
      currentIndex: menuIndex,
    });
  };

  handleMouse = ({ target }) => {
    if (target.tagName !== 'A') return;
    if (this.state.menuClicked) return;
    this.setState({
      hovered: true,
    });
  };

  //only works for A tags
  handleMouseOut = ({ target }) => {
    if (target.tagName !== 'A') return;

    this.setState({
      hovered: false,
    });
  };

  moveCircle = e => {
    TweenLite.to(this.circle, 0.3, {
      css: {
        left: e.clientX,
        top: e.clientY,
      },
    });
  };

  updateTranslations = () => {
    this.setState({
      loading: true,
    });

    window.setTimeout(() => {
      this.setState({
        loading: false,
      });

      this.render();
    }, 1500);
  };

  render() {
    const { children, hasFooter, ...props } = this.props;
    const { currentIndex, loading, hovered } = this.state;
    const pathName = props.location.pathname;

    return (
      <span
        onScroll={this.onScroll}
        onMouseOut={this.handleMouseOut}
        onMouseOver={this.handleMouse}
        onMouseMove={this.moveCircle}>
        <MetaTags />
        <Header />
        <RibbonRender menuIndex={props.location.pathname === '/' ? currentIndex : 0} />
        <BirdsAnimation toggleBirds={true} />
        {/* cursor */}
        <div
          ref={circle => (this.circle = circle)}
          className={cx('circle', {
            'circle--hovered': hovered,
          })}>
          <div className="circle-div" />
        </div>
        {/* MEMO: 言語切り替えを利用する場合はアンコメント */}
        {/* <SideInfo isActive={getLang()} update={this.updateTranslations} /> */}
        <Media query="(max-width: 960px)">
          {isSmaller => {
            return isSmaller ? (
              <MenuSm
                pathname={pathName}
                update={this.updateTranslations}
                updateMenuIndex={this.updateMenuIndex}
              />
            ) : (
              <Menu updateMenuIndex={this.updateMenuIndex} pathName={pathName} />
            );
          }}
        </Media>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <PoseGroup>
              <Transition key={props.location.pathname}>
                <span className="Layout-children">{children}</span>
              </Transition>
            </PoseGroup>
            {hasFooter && <Footer footerRef={this.footer} />}
          </>
        )}
      </span>
    );
  }
}

Layout.propTypes = {
  hasFooter: PropTypes.bool,
  hasRibbon: PropTypes.bool,
};

Layout.defaultProps = {
  hasFooter: true,
  hasRibbon: true,
};

export default Layout;
