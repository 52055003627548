import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import './AnimatedLink.scss';

const AnimatedLink = ({ text, link, href, className }) => {
  if (href) {
    return (
      <a href={href} target="_blank" className={`AnimatedLink ${className}`}>
        {text}
      </a>
    );
  }
  return (
    <Link to={link} className={`AnimatedLink ${className}`}>
      {text}
    </Link>
  );
};

AnimatedLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
};

AnimatedLink.defaultProps = {
  link: null,
  href: null,
  className: '',
};

export default AnimatedLink;
