import React from 'react';
import { Link } from 'react-scroll';
import cx from 'classnames';
import { menuList } from 'constants/menu';
import * as Gatsby from 'gatsby';
import posed from 'react-pose';
import { getLang, isEnglish, changeLang } from 'utils/lang';

import './MenuSm.scss';

const GatsbyLink = Gatsby.Link;

const Modal = posed.div({
  enterModal: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 200,
    },
  },
  exitModal: {
    scale: 0,
    opacity: 0,
    transition: { duration: 100 },
  },
});

const Shade = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

const MenuOne = posed.div({
  default: {
    rotate: 0,
    top: '18px',
  },
  enter: {
    rotate: 45,
    top: '22px',
  },
  exit: {
    rotate: 0,
    top: '18px',
  },
  transition: {
    ease: 'linear',
  },
});

const MenuTwo = posed.div({
  default: {
    rotate: 0,
    top: '25px',
  },
  enter: {
    rotate: -45,
    top: '22px',
    transition: {
      ease: 'linear',
    },
  },
  exit: {
    rotate: 0,
    top: '25px',
    transition: {
      ease: 'linear',
    },
  },
});

class MenuSm extends React.Component {
  state = {
    isActive: 0,
    isVisible: false,
  };

  toggleModal = () => {
    this.setState(prevState => ({ isVisible: !prevState.isVisible }));
  };

  handleSetActive = index => {
    this.setState({
      isActive: index,
    });
    this.props.updateMenuIndex(index);
  };

  changeLang = lang => {
    this.toggleModal();
    changeLang(lang);
    this.props.update();
  };

  render() {
    const { isVisible } = this.state;
    const { pathname } = this.props;
    const isTopPage = pathname === '/';

    return (
      <div className="MenuSm-wrapper">
        <a
          className={cx('MenuSm-button', {
            'MenuSm-button--active': isVisible,
          })}
          onClick={this.toggleModal}>
          <div className="MenuSm-icon">
            <MenuOne className="MenuSm-shape" pose={isVisible ? 'enter' : 'exit'} />
            <MenuTwo className="MenuSm-shapeTwo" pose={isVisible ? 'enter' : 'exit'} />
          </div>
        </a>
        <Modal key="modal" className="MenuModal" pose={isVisible ? 'enterModal' : 'exitModal'}>
          {isTopPage
            ? menuList.map((list, index) => (
                <Link
                  key={index}
                  onClick={this.toggleModal}
                  className="MenuModal-link"
                  activeClass="MenuModal-link--active"
                  to={list.link}
                  onSetActive={() => this.handleSetActive(index)}
                  spy={true}
                  smooth="ease"
                  duration={1000}>
                  <h5>{list.title}</h5>
                </Link>
              ))
            : menuList.map((list, index) => (
                <GatsbyLink
                  key={index}
                  onClick={this.toggleModal}
                  to={`#${list.link}`}
                  className="MenuModal-link">
                  <h5>{list.title}</h5>
                </GatsbyLink>
            ))}
          {/* MEMO: 言語切り替えを利用する場合はアンコメント */}
          {/* <h6 className="MenuModal-link-language">
            <a
              onClick={() => this.changeLang('en')}
              className={cx('MenuModal-link', {
                'MenuModal-link--activeLang': isEnglish(getLang()),
              })}>
              EN
            </a>{' '}
            |{' '}
            <a
              onClick={() => this.changeLang('ja')}
              className={cx('MenuModal-link', {
                'MenuModal-link--activeLang': !isEnglish(getLang()),
              })}>
              JA
            </a>
          </h6> */}
        </Modal>
        {isVisible && <Shade className="shade" />}
      </div>
    );
  }
}

export default MenuSm;
