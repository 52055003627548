import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Container.scss';

const Container = ({ children, bgColor, className, size }) => (
  <div
    className={cx('Container', {
      [`Container-${bgColor}`]: bgColor,
      [`Container--${size}`]: size,
      [className]: className,
    })}>
    {children}
  </div>
);

Container.propTypes = {
  bgColor: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  bgColor: null,
  size: null,
  className: null,
};

export default Container;
