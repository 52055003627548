import React from 'react';
import cx from 'classnames';
import { Link } from 'react-scroll';
import * as Gatsby from 'gatsby';
import Container from 'components/Container/Container';
import { menuList } from 'constants/menu';
import './Menu.scss';

const GatsbyLink = Gatsby.Link;
class Menu extends React.Component {
  state = {
    activeIndex: 0,
  };

  handleSetActive = index => {
    this.setState({
      activeIndex: index,
    });
    this.props.updateMenuIndex(index);
  };

  render() {
    const { pathName } = this.props;
    const { activeIndex } = this.state;
    return (
      <Container size="lg" className="Menu">
        {pathName === '/' && <div className={`Menu-activeBox Menu-activeBox--${activeIndex}`} />}
        {pathName === '/'
          ? menuList.map((list, index) => (
              <Link
                key={index}
                className={cx('Menu-link', {
                  // 'Menu-link--active': activeIndex === 0 && index === 0,
                })}
                activeClass="Menu-link--active"
                to={list.link}
                spy={true}
                smooth="ease"
                onSetActive={() => this.handleSetActive(index)}
                duration={1000}>
                {list.title}
              </Link>
            ))
          : menuList.map((list, index) => (
              <GatsbyLink
                key={index}
                onClick={this.toggleModal}
                to={`#${list.link}`}
                className="Menu-link">
                {list.title}
              </GatsbyLink>
            ))}
      </Container>
    );
  }
}

export default Menu;
