import en from 'locale/en/translations.json';
import ja from 'locale/ja/translations.json';
import window from 'global';
import ls from 'store';

export const getLangFile = () => {
  // MEMO: 言語切り替えを利用する場合はアンコメント
  // if (isEnglish(getLang())) return en;
  return ja;
};

export const isEnglish = lang => {
  return lang === 'en' || lang === 'en-US';
};

export const getLang = () => {
  const savedLang = ls && ls.get('lang');
  if (savedLang) return savedLang;

  let lang;
  if (window.navigator && window.navigator.languages) {
    lang = window.navigator.language || window.navigator.userLanguage;
  }

  return lang ? lang : 'ja';
};

export const changeLang = lang => {
  ls.set('lang', lang);
  // window.location.reload();
};
