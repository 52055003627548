import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

const TweenWrapper = ({ triggerName, toggleClass, duration, children }) => (
  <>
    <div id={triggerName} style={{ position: 'absolute' }} />
    <Controller>
      <Scene triggerElement={`#${triggerName}`} classToggle={toggleClass} duration={duration}>
        {progress => (
          <Tween
            to={{
              backgroundColor: '#5F16FC',
            }}
            ease="Strong.easeOut"
            totalProgress={progress}
            paused>
            {children}
          </Tween>
        )}
      </Scene>
    </Controller>
  </>
);

export default TweenWrapper;
